.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ----------------- */
pre {
  /* display: block;
  font-family: monospace;
  white-space: pre;
  margin: 1em 0px; */
  padding: 1em 0.5em;
  background: rgba(0, 0, 0, 0.2);
}
code {
  font-family: "Courier New", monospace;
}

/* ----- Exp ----- */
.panel {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  max-width: 40%;
  margin: 0;
  box-shadow: 0 0 1em;
  transition: transform 0.3s ease;
}
.panel-close {
  transform: translateX(-110%) scale(0); /* Close */
}
.panel-open {
  transform: translateX(1em) scale(1); /* Open */
}

.panel-preview-scale {
  transform-origin: top right;
  transform: scale(calc(100 / 180));
  height: calc(100% * (180 / 100));
  transition: transform 0.3s ease;
}
