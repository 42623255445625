.layout-header-wrapper {
  padding: 1em;
  /* background: #1565c0; */
  color: #fff;
  display: flex;
  justify-content: center;
  /* padding: 5em 0; */
  /* padding: 4.5em 0; */
  padding: 0;
  overflow: hidden;
  position: relative;
  z-index: 0;

  /* position: sticky; */
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;

  /* background: linear-gradient(
        0.25turn,
        rgba(0, 0, 0, 0.85) 0%,
        rgba(0, 0, 0, 0.65) 30%,
        rgba(0, 0, 0, 0) 75%
      )
      center center / cover no-repeat,
    url("/hawaii/shark-hero.jpg") rgb(0, 0, 0); */

  /* background: linear-gradient(
        -0.52turn,
        rgba(0, 0, 0, 0.85) 0%,
        rgba(0, 0, 0, 0.65) 40%,
        rgba(0, 0, 0, 0) 95%
      )
      center center / cover no-repeat,
    url(/hawaii/shark-hero.jpg) rgb(0, 0, 0); */

  /* background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.85) 0%,
        rgba(0, 0, 0, 0.65) 30%,
        rgba(0, 0, 0, 0.45) 70%,
        rgba(0, 0, 0, 0) 95%
      )
      center center / cover no-repeat,
    url(/hawaii/shark-hero.jpg) rgb(0, 0, 0) fixed; */
  background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.85) 0%,
        rgba(0, 0, 0, 0.65) 30%,
        rgba(0, 0, 0, 0.45) 70%,
        rgba(0, 0, 0, 0) 95%
      )
      center center / cover no-repeat,
    url(/hawaii/hawaii-playa.jpg) rgb(0, 0, 0) fixed;
  background-size: cover;
  background-position: 50%;
  background-position-x: 50%;
  background-position-y: 50%;
  transition: background-position 0.25s ease-out;
}
.layout-header-wrapper h1,
.layout-header-wrapper h2,
.layout-header-wrapper h3 {
  color: #fff;
}

.layout-header {
  max-width: 1450px;
  position: relative;
  z-index: 1;

  /* width: 100vw;
    height: 100vh;
    max-height: 670px;
    display: flex;
    flex-direction: row; */

  width: 100vw;

  /* min-height: 40em;
  height: 100vh;
  max-height: 40em; */
  /* padding: 3.5rem 0; */
  padding: 0.5rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 100vh;
  /* height: 100vh;
  max-height: 40em; */
}
@media (min-width: 1100px) {
  .layout-header {
    padding: 3.5rem 0;
    min-height: 40em;
    /* height: 100vh; */
    /* max-height: 40em; */
  }
}

.layout-header .text {
  /* max-width: 50%; */
  padding: 2rem 1rem;
  /* text-align: left; */
  font-size: 1.2em;
  text-shadow: 0 0 0.6em rgba(0, 0, 0, 0.75);
  /* background-color: #000; */
}
@media (min-width: 1100px) {
  .layout-header .text {
    padding: 2rem 5rem;
  }
}
.layout-header .text img {
  max-width: 100%;
}
@media (min-width: 450px) {
  .layout-header .text img {
    max-width: 16em;
  }
}
.layout-header-logo {
  position: absolute;
  z-index: 0;
  opacity: 0.3;
  left: 50%;
  top: 20%;
}
.layout-header-logo img {
  width: 25rem;
  will-change: transform;
  animation: spin 10s cubic-bezier(0, 0, 0, 1) infinite 2.5s;
}
