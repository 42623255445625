body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
  font-size: 1.25em;
  line-height: 1.5;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 450px) {
  body {
    font-size: 1em;
  }
}
@media (min-width: 1100px) {
  body {
    font-size: 1.125em;
  }
}
@media (min-width: 1400px) {
  body {
    font-size: 1.5em;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: currentColor;
  color: #0097a7;
  text-decoration: none;
  opacity: 0.75;
  transition: opacity 0.2s ease;
}
a:hover {
  opacity: 1;
}

h1,
h2,
h3 {
  font-weight: 400;
  color: #fecc02;
  color: #0097a7;
}
h1 {
  font-size: 2.25em;
  margin: 0.5em 0;
}
@media (min-width: 450px) {
  h1 {
    font-size: 3em;
    margin: 0.5em 0;
  }
}
h3 {
  margin: 0 0 0.5rem;
}

.button {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 0.2em;
  color: white;
  /* background-color: transparent; */
  background-color: #222;
  padding: 0.7em;
  transition: color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-weight: 600;
}
.button:hover {
  /* color: #1565c0; */
  color: #004a52;
  background-color: white;
  text-shadow: none;
  background-color: white;
}
/* ---------------------- */

.layout-content {
  position: relative;
  background-color: #fff;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.layout-content img {
  max-width: 100%;
}

.shadow {
  box-shadow: 0.1em -0.1em 5px 0px rgba(0, 0, 0, 0.6);
}

@keyframes spin {
  10% {
    transform: rotate(60deg);
  }
  100% {
    transform: rotate(60deg);
  }
}

/* ------------------ ADMIN ----------------- */

.admin-view {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.admin-view-form {
  max-width: 40%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  background: rgba(256, 256, 256, 0.85);
  box-shadow: 0 0 1em;
}
.admin-view-preview {
  flex: 1;
  background: grey;
  position: relative;
}
.admin-view-preview iframe {
  border: 2px solid #484848;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  box-sizing: border-box;

  /* transform: scale(0.6); */
  /* height: calc(100% * 1.5); */
  transform-origin: top right;
  transition: transform 0.3s ease;
}
